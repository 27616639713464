<template>
  <div>
    <!-- select 2 demo -->

    <div>
      <!-- Table Top -->
      <div>
        <!-- SideBar For Department -->
        <b-sidebar
          id="sidebar-Department"
          bg-variant="white"
          v-model="visibility1"
          sidebar-class="sidebar-lg"
          right
          no-header
          backdrop
          shadow
        >
          <div
            class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
          >
            <h5 class="mb-0">
              {{ sidebarTitle }}
            </h5>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hideDepSideBar()"
            />
          </div>
          <b-form class="p-2" @submit.prevent>
            <b-row class="mt-1">
              <b-col md="12">
                <b-form-group
                  label="Department name *"
                  invalid-feedback="Department name is required."
                >
                  <b-form-input
                    id="mc-first-name"
                    placeholder="Enter department name"
                    ref="depname"
                    v-model="depObj.name"
                    @focusout="CheckDepName()"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12" v-if="settObj">
                <b-form-group
                  label="Whatsapp From *"
                  invalid-feedback="Whatsapp from is required."
                >
                  <v-select
                    ref="wcampus"
                    v-model="depObj.whatsAppCampusID"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="$store.state.branches"
                    :clearable="false"
                    label="branch"
                    :reduce="(val) => val.id"
                    placeholder="Select"
                  />
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="success"
                  class="mr-1 fullWidth"
                  @click="DepAdd()"
                  :disabled="request"
                  block
                >
                  <b-spinner v-if="request" small type="grow" />
                  <span v-if="request == false"> {{ sidebarButton }} </span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
          <sidebar-content />
        </b-sidebar>

        <!-- SideBar For Classes -->
        <b-sidebar
          id="sidebar-right"
          ref="mySidebar"
          bg-variant="white"
          v-model="visibility"
          sidebar-class="sidebar-lg"
          right
          no-header
          backdrop
          shadow
        >
          <div
            class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
          >
            <h5 class="mb-0">
              {{ sidebarTitle }}
            </h5>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hideSideBar()"
            />
          </div>
          <b-form class="p-2" @submit.prevent>
            <b-row class="mt-1">
              <b-col md="12">
                <b-form-group
                  label="Department *"
                  invalid-feedback="Department is required."
                  ref="department"
                >
                  <v-select
                    v-model="myObj.cls.departmentID"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="this.myDepartments"
                    :clearable="false"
                    ref="status"
                    label="name"
                    :reduce="(val) => val.id"
                    :disabled="dataloading"
                    placeholder="Select Department"
                    @input="CheckDepartment()"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Class name *"
                  invalid-feedback="Class is required."
                >
                  <b-form-input
                    id="mc-first-name"
                    placeholder="Enter class name"
                    v-validate="'required'"
                    name="classField"
                    ref="name"
                    v-model="myObj.cls.name"
                    @input="CheckName()"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12" v-if="selecting">
                <b-form-group
                  label="Select Sections *"
                  invalid-feedback="Sections is required."
                  ref="sections"
                >
                  <!-- <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    multiple
                    v-model="myObj.sections"
                    taggable
                    push-tags
                    placeholder="Add Sections"
                    label="title"
                  /> -->
                  <div
                    style="
                      border: 1px solid lightgray;
                      padding: 5px;
                      border-radius: 5px;
                    "
                  >
                    <b-badge
                      class="sectionBadge"
                      v-for="sec in sections"
                      :key="sec"
                      :id="'sectionBadge' + sec.id"
                      @click="selectSection(sec.id)"
                    >
                      {{ sec.section }}
                    </b-badge>
                    <!-- <b-badge
                    class="sectionBadge"
                    style=""
                    v-for="sec in sections"
                    :key="sec"
                  >
                    {{ sec.section }}
                  </b-badge> -->
                    <b-button
                      @click="addSection()"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="btn-icon rounded-circle"
                    >
                      <feather-icon
                        style="height: 15px; width: 15px"
                        icon="PlusIcon"
                      />
                    </b-button>
                  </div>
                  <!-- <div></div> -->
                </b-form-group>
              </b-col>
              <b-col md="12" v-if="!selecting">
                <b-form-group label="Add new section">
                  <div style="display: flex">
                    <b-form-input
                      placeholder="Enter section name"
                      v-validate="'required'"
                      name="classField"
                      v-model="sectionObj.section"
                    />
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-danger"
                      class="btn-icon mr-0 ml-sm-1"
                      @click="HideSection()"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="primary"
                      class="btn-icon mr-0 ml-sm-1"
                      @click="postSection()"
                    >
                      <feather-icon icon="CheckIcon" />
                    </b-button>
                  </div>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label="Arrival time"
                  invalid-feedback="Arrival time is required."
                  ref="arrival"
                >
                  <flat-pickr
                    v-model="myObj.cls.startsAt"
                    class="form-control"
                    :config="{
                      enableTime: true,
                      noCalendar: true,
                      altFormat: 'h:i',
                      altInput: true,
                      dateFormat: 'Y-m-d\\TH:i:S',
                      defaultHour: 13,
                    }"
                    placeholder="Enter time"
                  />
                  <!-- @on-close="testing()" -->
                </b-form-group>
              </b-col>

              <b-col md="12" v-if="selecting">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="success"
                  class="mr-1 fullWidth"
                  @click="Add()"
                  :disabled="request"
                  block
                >
                  <b-spinner v-if="request" small type="grow" />
                  <span v-if="request == false"> {{ sidebarButton }} </span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
          <sidebar-content />
        </b-sidebar>

        <b-sidebar
          id="sidebar-Department"
          bg-variant="white"
          v-model="visibility3"
          sidebar-class="sidebar-lg"
          right
          no-header
          backdrop
          shadow
        >
          <div
            class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
          >
            <h5 class="mb-0">Shift Classes</h5>

            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="visibility3 = false"
            />
          </div>
          <b-form class="p-2" @submit.prevent>
            <b-row class="mt-1">
              <b-table
                :items="shiftData"
                :fields="shiftFields"
                show-empty
                responsive
                hover
              >
                <template #cell(current_class)="data">
                  <!-- style="outline" -->
                  {{ getClassName(data.item.current_class) }}
                  <!-- <v-select
                    v-model="data.item.current_class"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="allClasses"
                    :reduce="(val) => val.id"
                    label="name"
                    :clearable="false"
                    placeholder=""
                    disabled
                  /> -->
                </template>
                <template #cell(move_to)="data">
                  <v-select
                    v-model="data.item.move_to"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="allClasses"
                    :reduce="(val) => val.id"
                    label="name"
                    :clearable="false"
                    placeholder=""
                  />
                </template>
              </b-table>

              <b-col md="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  @click="Shift()"
                  :disabled="shifting"
                  block
                >
                  <b-spinner v-if="shifting" small type="grow" />
                  <span v-else> Shift </span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
          <sidebar-content />
        </b-sidebar>

        <b-sidebar
          id="sidebar-Department"
          bg-variant="white"
          v-model="visibility4"
          sidebar-class="sidebar-lg"
          right
          no-header
          backdrop
          shadow
        >
          <div
            class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
          >
            <h5 class="mb-0">Shift Class</h5>

            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="visibility4 = false"
            />
          </div>
          <b-form class="p-2" @submit.prevent>
            <b-row class="mt-1">
              <b-col md="12">
                <b-form-group
                  label="From Class"
                  invalid-feedback="Class is required."
                  ref="from_cls"
                >
                  <v-select
                    v-model="singleObj.clsFrom"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="allClasses"
                    :reduce="(val) => val.id"
                    label="name"
                    :clearable="false"
                    placeholder="select class"
                    @input="fillCurrent()"
                  />
                  <!-- :options="
                      allClasses.filter((el) => el.id != singleObj.clsTo)
                    " -->
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label="From Section"
                  invalid-feedback="Section is required."
                  ref="from_sec"
                >
                  <v-select
                    v-model="singleObj.secFrom"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="currentSections"
                    :reduce="(val) => val.id"
                    label="section"
                    :clearable="false"
                    placeholder="select section"
                    @input="checkSame()"
                  />
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label="Move To"
                  invalid-feedback="Class is required."
                  ref="move_cls"
                >
                  <v-select
                    v-model="singleObj.clsTo"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="allClasses"
                    :reduce="(val) => val.id"
                    label="name"
                    :clearable="false"
                    placeholder="select class"
                    @input="fillMove()"
                  />
                  <!-- :options="
                      allClasses.filter((el) => el.id != singleObj.clsFrom)
                    " -->
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label="Move Section"
                  invalid-feedback="Section is required."
                  ref="move_sec"
                >
                  <v-select
                    v-model="singleObj.secTo"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="moveSections"
                    :reduce="(val) => val.id"
                    label="section"
                    :clearable="false"
                    placeholder="select section"
                    @input="checkSame()"
                  />
                </b-form-group>
              </b-col>

              <b-col md="12" class="mt-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  @click="ShiftSingle()"
                  :disabled="shifting"
                  block
                >
                  <b-spinner v-if="shifting" small type="grow" />
                  <span v-else> Shift </span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
          <sidebar-content />
        </b-sidebar>

        <b-card>
          <b-row align-h="end" class="text-right">
            <b-col class="mt-xl-0 mt-lg-0 mt-md-0 mt-sm-1 mt-1" cols="11">
              <b-dropdown
                style="margin-inline: 2px; margin-bottom: 2px"
                v-for="(d, ind) in this.myDepartments"
                :key="ind"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                split
                :text="d.name"
                :variant="current == d.id ? 'primary' : 'outline-primary'"
                right
                @click="LoadClasses(d.id)"
              >
                <b-dropdown-item @click="EditDep(d.id)"> Edit </b-dropdown-item>
                <b-dropdown-item @click="DeleteDep(d.id)">
                  Delete
                </b-dropdown-item>

                <b-dropdown-divider />
              </b-dropdown>
              <!-- </div> -->
            </b-col>
            <b-col
              class="mt-xl-0 mt-lg-0 mt-md-0 mt-sm-1 mt-1"
              style="margin-right: 8px"
              cols="*"
            >
              <b-button
                @click="OpenDepartmentF()"
                style=""
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon rounded-circle"
              >
                <feather-icon
                  style="height: 20px; width: 20px"
                  icon="PlusIcon"
                />
              </b-button>
            </b-col>
          </b-row>
          <b-row class="mt-1 mb-50">
            <b-col xl="2" lg="3" md="4" sm="12" cols="12">
              <b-button
                @click="AddOpen()"
                style="width: 100%"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
              >
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle"> Add Class</span>
              </b-button>
            </b-col>

            <b-col
              class="mt-lg-0 mt-md-0 mt-sm-1 mt-1 d-flex align-items-center justify-content-between"
              xl="10"
              lg="9"
              md="8"
              sm="12"
              cols="12"
            >
              <b-form-group class="mb-0 pr-50" style="width: 100%">
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>

                  <b-form-input v-model="searchQuery" placeholder="Search...">
                  </b-form-input>
                </b-input-group>
              </b-form-group>
              <!-- <b-button
                v-if="rights.edit"
                variant="outline-primary"
                v-b-tooltip.hover.top
                title="Shift Classes"
                class="btn-icon mr-50"
                :disabled="loadingSh"
                @click="openShift()"
              >
                <b-spinner v-if="loadingSh" small />
                <feather-icon v-else icon="CheckCircleIcon" class="" />
              </b-button> -->
              <b-button
                v-if="rights.edit"
                variant="outline-primary"
                v-b-tooltip.hover.top
                title="Shift Class"
                class="btn-icon"
                :disabled="loadingSh"
                @click="openSingle()"
              >
                <feather-icon icon="ShuffleIcon" class="" />
              </b-button>
            </b-col>
          </b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
          <b-table
            class="mt-1"
            ref="selectableTable"
            :items="items"
            :fields="fields"
            :busy="gridLoading"
            :per-page="perPage"
            :current-page="currentPage"
            show-empty
            responsive
            hover
            :selectable="rights.edit"
            select-mode="single"
            @row-selected="Edit($event[0].cls.id)"
          >
            <template #empty="scope">
              <h3 class="mt-1 mb-1" style="text-align: center">
                No records found
              </h3>
            </template>
            <template #table-busy>
              <div
                class="d-flex justify-content-center mb-3"
                style="margin-top: 50px"
              >
                <b-spinner
                  style="width: 3rem; height: 3rem"
                  type="grow"
                  variant="primary"
                  label="Spinning"
                ></b-spinner>
              </div>
            </template>
            <template #cell(name)="data">
              <b-badge style="margin-inline: 2px" variant="light-primary">
                {{ data.item.cls.name }}
              </b-badge>
            </template>

            <template #cell(sections)="data">
              <div v-if="data.item.sections != null">
                <b-badge
                  style="margin-inline: 2px"
                  v-for="i in data.item.sections"
                  :key="i"
                  variant="light-primary"
                >
                  {{ i.section }}
                </b-badge>
              </div>
            </template>

            <template #cell(actions)="data">
              <div class="">
                <b-button
                  v-if="rights.edit"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  v-b-tooltip.hover.left
                  title="Edit"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="Edit(data.item.cls.id)"
                >
                  <feather-icon icon="EditIcon" class="" />
                </b-button>

                <!-- <b-button
                  v-if="rights.delete"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  v-b-tooltip.hover.right
                  title="Delete"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="Delete(data.item.cls.id)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button> -->

                <b-button
                  v-if="rights.edit"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  v-b-tooltip.hover.right
                  title="Left Class"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="leftClass(data.item.cls.id)"
                >
                  <feather-icon icon="LogOutIcon" />
                </b-button>

                <!-- <b-button
                  v-if="
                    rights.edit &&
                    (currentPage - 1) * perPage + data.index + 1 <=
                      items.length - 1
                  "
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  v-b-tooltip.hover.right
                  title="Move Down"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                >
                  <feather-icon icon="ArrowDownIcon" />
                </b-button>
                <b-button
                  v-if="
                    rights.edit &&
                    (currentPage - 1) * perPage + data.index + 1 != 1
                  "
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  v-b-tooltip.hover.right
                  title="Move Up"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                >
                  <feather-icon icon="ArrowUpIcon" />
                </b-button> -->
              </div>
            </template>
          </b-table>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, between } from "vuelidate/lib/validators";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { forEach } from "postcss-rtl/lib/affected-props";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    //Multiselect,
    flatPickr,
    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    // BFormCheckbox,
    BForm,
    BPagination,
    BSpinner,

    BDropdown,
    BDropdownItem,

    vSelect,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    let right = {};
    this.$store.state.menu.forEach((el) => {
      el.children.forEach((ch) => {
        right[ch.route] = {
          view: ch.view,
          add: ch.add,
          edit: ch.edit,
          delete: ch.delete,
        };
      });
    });
    // console.log(this.right);
    this.$store.commit("setRights", right);
    // console.log(this.$store.state.rights);

    if (!this.$store.state.rights[this.$route.name]) {
      // console.log(this.$store.state.rights[this.$route.name]);
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];
      this.LoadDepartments();
      this.LoadClasses(0);
      this.loadSettings();
    }
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),
    filters: function () {
      return this.items.filter((pro) => {
        return pro.name.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
  },
  data() {
    return {
      rights: {},
      myDepartments: [],
      request: false,
      colorDep: [],
      visibility1: false,
      visibility3: false,
      visibility4: false,
      singleObj: {
        clsFrom: 0,
        secFrom: 0,
        clsTo: 0,
        secTo: 0,
      },
      currentSections: [],
      moveSections: [],
      color: [
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
      ],

      sidebarTitle: "Add Class",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataloading: false,
      gridLoading: false,
      visibility: false,
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      totalRows: null,

      errors: {
        status: false,
      },

      index: null,
      loadingSh: false,
      shifting: false,
      shiftData: [],
      shiftFields: [
        { label: "Class", key: "current_class" },
        { label: "Move to", key: "move_to" },
      ],
      fields: [
        { label: "Class", key: "name" },
        { label: "Sections", key: "sections" },
        { key: "actions", label: "actions" },
      ],
      allClasses: [],
      items: [],
      sections: [],
      selecting: true,
      selected: null,
      sectionObj: {
        id: 0,
        section: "",
        campusID: this.$store.state.userData.cId,
      },
      allSectionIDs: [],
      rangeDate: null,
      myObj: {
        cls: {
          id: 0,
          name: "",
          sections: "",
          departmentID: 0,
          campusID: this.$store.state.userData.cId,
          startsAt: null,
        },
        sectionIDs: [],
      },
      depObj: {
        id: 0,
        name: "",
        campusID: this.$store.state.userData.cId,
      },
      detailObj: {},
      timeout: 10000,
      current: 0,
      settObj: null,
    };
  },
  validations: {
    depObj: {
      name: {
        required,
        minLength: minLength(4),
      },
    },
    age: {
      between: between(20, 30),
    },
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    testing() {
      console.log(this.myObj.cls.startsAt);
    },

    async loadSettings() {
      var obj = {
        url:
          this.$store.state.domain +
          "Settings?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      let res = await this.get(obj);
      // console.log(res);
      this.settObj = res.find((el) => el.key == "multi_whatsapp");
      // console.log(this.settObj);
    },

    getClassName(id) {
      return this.allClasses.find((el) => el.id == id).name;
    },

    openSingle() {
      this.singleObj = {
        clsFrom: 0,
        secFrom: 0,
        clsTo: 0,
        secTo: 0,
      };
      this.visibility4 = true;
      this.currentSections = [];
      this.moveSections = [];
    },
    fillCurrent() {
      this.singleObj.secFrom = 0;

      this.currentSections = this.items.find(
        (el) => el.cls.id == this.singleObj.clsFrom
      ).sections;

      this.checkSame();
    },
    fillMove() {
      this.singleObj.secTo = 0;

      this.moveSections = this.items.find(
        (el) => el.cls.id == this.singleObj.clsTo
      ).sections;

      this.checkSame();
    },
    checkSame() {
      this.currentSections = this.items.find(
        (el) => el.cls.id == this.singleObj.clsFrom
      ).sections;

      this.moveSections = this.items.find(
        (el) => el.cls.id == this.singleObj.clsTo
      ).sections;

      if (this.singleObj.clsTo == this.singleObj.clsFrom) {
        // for current
        this.currentSections = this.currentSections.filter(
          (el) => el.id != this.singleObj.secTo
        );

        // for move
        this.moveSections = this.moveSections.filter(
          (el) => el.id != this.singleObj.secFrom
        );
      }
    },

    async ShiftSingle() {
      if (
        this.singleObj.clsFrom == 0 ||
        this.singleObj.secFrom == 0 ||
        this.singleObj.clsTo == 0 ||
        this.singleObj.secTo == 0
      ) {
        this.$bvToast.toast("Please enter the details!", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-right",
        });
      } else {
        console.log(this.singleObj);
        this.shifting = true;

        var status = await this.post({
          url:
            this.$store.state.domain +
            "MyClasses/ShiftSingle?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: this.singleObj,
          message: "Class shifted successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.shifting = false;

        if (status) {
          this.visibility4 = false;
        }
      }
    },

    async openShift() {
      this.loadingSh = true;
      var obj = {
        url:
          this.$store.state.domain +
          "Students/LoadShiftData?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.shiftData = await this.get(obj);
      // console.log(this.shiftData);
      this.loadingSh = false;
      this.visibility3 = true;
    },
    async Shift() {
      this.shifting = true;
      var status = await this.post({
        url:
          this.$store.state.domain +
          "Students/ShiftClass?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        body: this.shiftData,
        message: "Classes shift successfully.",
        context: this,
        token: this.$store.state.userData.token,
      });
      this.shifting = false;
      if (status) {
        this.visibility3 = false;
      }
    },

    async EditDep(id) {
      var obj = {
        url:
          this.$store.state.domain +
          "Departments/GetSelected?id=" +
          id +
          "&db=" +
          this.$store.state.userData.db,
        token: this.$store.state.userData.token,
      };
      this.depObj = await this.get(obj);
      // console.log("editObj:", this.depObj);
      this.sidebarTitle = "Edit Department";
      this.sidebarButton = "Update";
      this.visibility1 = true;
      var elem = this.$refs["depname"];
      elem.state = undefined;
    },

    OpenDepartmentF() {
      this.depObj = {
        id: 0,
        name: "",
        campusID: this.$store.state.userData.cId,
      };
      this.sidebarTitle = "Add Department";
      this.sidebarButton = "Save";
      this.visibility1 = true;
      var elem = this.$refs["depname"];
      elem.state = undefined;
    },

    async Edit(id) {
      var obj = {
        url:
          this.$store.state.domain +
          "MyClasses/GetSelectedClass?db=" +
          this.$store.state.userData.db +
          "&clsID=" +
          id,
        token: this.$store.state.userData.token,
      };
      let result = await this.get(obj);
      // console.log("editObj", result);
      this.myObj.cls = result.cls;
      await this.getSections();

      this.visibility = true;
      this.allSectionIDs = [];
      result.sections.forEach((el) => {
        this.allSectionIDs.push(el.id);
        // console.log(this.allSectionIDs);
        const myElement = document.getElementById("sectionBadge" + el.id);
        myElement.style.backgroundColor = "var(--primary)";
        myElement.style.color = "white";
      });

      this.sidebarTitle = "Edit Class";
      this.sidebarButton = "Update";
      this.buttonIcon = "EditIcon";
      var elem = this.$refs["name"];
      elem.state = undefined;
      var elem = this.$refs["department"];
      elem.state = undefined;
      var elem = this.$refs["sections"];
      elem.state = undefined;
    },

    async AddOpen() {
      this.allSectionIDs = [];
      this.getSections();
      this.visibility = true;
      this.myObj.cls.id = 0;
      this.myObj.cls.departmentID = 0;
      this.myObj.cls.campusID = this.$store.state.userData.cId;
      this.myObj.cls.sections = "";
      this.myObj.cls.name = "";
      this.myObj.cls.startsAt = null;
      this.sidebarTitle = "Add Class";
      this.sidebarButton = "Save";
      var elem = this.$refs["name"];
      elem.state = undefined;
      var elem = this.$refs["department"];
      elem.state = undefined;
      var elem = this.$refs["sections"];
      elem.state = undefined;
    },
    async getSections() {
      var obj = {
        url:
          this.$store.state.domain +
          "Sections?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.sections = await this.get(obj);
      // console.log("sections:", this.sections);
      this.sections = this.sections.filter(
        (el) => el.section != null && el.section != ""
      );
      this.sections = this.sections.sort((s1, s2) => {
        // console.log(s1);
        // console.log(s2);
        if (s1 != null && s2 != null && s1 != undefined && s2 != undefined)
          s1.section.toUpperCase() > s2.section.toUpperCase()
            ? 1
            : s1.section.toUpperCase() < s2.section.toUpperCase()
            ? -1
            : 0;
      });
      // console.log(sorted);
    },
    addSection() {
      this.selecting = false;
      this.sectionObj.section = "";
      // console.log("secObj:", this.sectionObj);
    },
    async postSection() {
      // console.log("secObj", this.sectionObj);
      if (this.sectionObj.section.trim() == "") {
        return this.$bvToast.toast("Please enter the section name.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-right",
        });
      } else {
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Sections?db=" +
            this.$store.state.userData.db,
          body: this.sectionObj,
          message: "Section added successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) {
          this.selecting = true;
          this.getSections();
        }
      }
    },
    HideSection() {
      this.selecting = true;
      this.sectionObj.section = "";
    },
    selectSection(id) {
      // console.log(id);
      if (this.allSectionIDs.length == 0) {
        this.allSectionIDs.push(id);
        const myElement = document.getElementById("sectionBadge" + id);
        myElement.style.backgroundColor = "var(--primary)";
        myElement.style.color = "white";
      } else {
        var data = [];
        this.allSectionIDs.forEach((el) => {
          if (el != id) {
            data.push(el);
          } else {
            const myElement = document.getElementById("sectionBadge" + id);
            myElement.style.backgroundColor = "white";
            myElement.style.color = "var(--primary)";
          }
        });
        if (this.allSectionIDs.length == data.length) {
          this.allSectionIDs.push(id);
          const myElement = document.getElementById("sectionBadge" + id);
          myElement.style.backgroundColor = "var(--primary)";
          myElement.style.color = "white";
        } else {
          this.allSectionIDs = data;
        }
      }
      // console.log("data", data);
      // console.log("sec_ids", this.allSectionIDs);
      this.CheckSections();
    },
    CheckRequired(name) {
      var elem = this.$refs[name];
      if (this.myObj.name == "" || this.myObj.name > 20) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }

      // return (elem.state = this.$v.depObj.name.required);
    },

    CheckName() {
      var elem = this.$refs["name"];
      if (this.myObj.cls.name == "" || this.myObj.cls.name > 20) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDepName() {
      var elem = this.$refs["depname"];
      if (this.depObj.name == "" || this.depObj.name == null) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDepartment() {
      var elem = this.$refs["department"];
      if (this.myObj.cls.departmentID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckSections() {
      var elem = this.$refs["sections"];
      if (this.allSectionIDs.length == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    hideSideBar() {
      this.visibility = false;
      this.allSectionIDs = [];
    },
    hideDepSideBar() {
      this.visibility1 = false;
    },

    Form() {
      this.$router.push("/apps/schoolinfo");
    },

    checkTitle() {
      var elem = this.$refs["title"];
      return (elem.state = this.myObj.name.length > 2 ? true : false);
    },

    checkStatus() {
      if (this.myObj.Status == "") {
        return (this.errors.status = true);
      } else {
        return (this.errors.status = false);
      }
    },
    OpenAddModal() {
      this.$bvModal.show("modal-login");
      this.myObj.flavourId = 0;
      this.myObj.vendorId = this.$store.state.userData.userID;
      this.myObj.Name = "";
      this.myObj.Status = "";
    },
    OpenEditModal(row) {
      this.$bvModal.show("modal-login");
      this.myObj.flavourId = row.flavourId;
      this.myObj.vendorId = row.vendorId;
      this.myObj.Name = row.Name;
      this.myObj.Status = row.Status;
    },

    async LoadDepartments() {
      var obj = {
        url:
          this.$store.state.domain +
          "departments?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.myDepartments = await this.get(obj);
    },

    async LoadClasses(dID) {
      this.current = dID;
      this.gridLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId +
          "&dID=" +
          dID,
        token: this.$store.state.userData.token,
      };
      this.items = await this.get(obj);
      if (dID == 0) {
        this.allClasses = [];
        this.items.forEach((el) => {
          this.allClasses.push(el.cls);
        });
      }
      // console.log("classes", this.items);
      // this.items = [];

      if (this.items.length != 0) {
        this.totalRows = this.items.length;
      }
      this.gridLoading = false;
    },

    async DepAdd() {
      this.CheckDepName();
      if (this.CheckDepName() == false) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        if (this.depObj.id == 0) {
          //Add
          this.request = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "departments?db=" +
              this.$store.state.userData.db,
            body: this.depObj,
            message: "Department added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          this.visibility1 = false;
          if (status) this.LoadDepartments();
        } else {
          //Edit
          this.request = true;
          var status = await this.put({
            url:
              this.$store.state.domain +
              "departments/" +
              this.depObj.id +
              "?db=" +
              this.$store.state.userData.db,
            body: this.depObj,
            message: "Department updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          if (status) {
            this.visibility1 = false;
            this.LoadDepartments();
          }
        }
      }
    },

    async DeleteDep(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "departments/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          message: "Department removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadDepartments();
      }
    },

    async Add() {
      // console.log("classObj", this.myObj);
      this.CheckName();
      this.CheckDepartment();
      this.CheckSections();
      if (
        this.CheckName() == false ||
        this.CheckDepartment() == false ||
        this.CheckSections() == false
      ) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        // this.myObj.sections = this.myObj.sections.toString();
        this.myObj.sectionIDs = this.allSectionIDs;
        if (this.myObj.cls.startsAt == "") this.myObj.cls.startsAt = null;

        if (this.myObj.cls.id == 0) {
          //Add
          this.request = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "myclasses/Add?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "Class added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          this.visibility = false;
          if (status) this.LoadClasses(0);
        } else {
          //Edit
          this.request = true;
          var status = await this.put({
            url:
              this.$store.state.domain +
              "myclasses/Edit?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "Class updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          this.visibility = false;
          if (status) this.LoadClasses(0);
        }
      }
    },

    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "myclasses/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          message: "Class removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadClasses(0);
      }
    },

    async leftClass(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "Do you want to mark whole class as left.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      });

      if (result.isConfirmed) {
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Students/LeftWholeClass?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&classID=" +
            id,

          body: null,
          message: "Class marked as left",
          context: this,
          token: this.$store.state.userData.token,
        });
        // if (status) this.LoadClasses();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.sectionBadge {
  margin-inline: 5px;
  margin-top: 5px;
  margin-bottom: 3px;
  padding: 10px;
  border: 2px solid var(--primary);
  color: var(--primary);
  background-color: white;
  cursor: pointer;
}
</style>
